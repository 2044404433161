@import "../../../../style/mixins.scss";

.pnTileGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2.8rem;
  grid-row-gap: 5.2rem;
  width: 80%;
  padding: 25px 0;
  @include medium-screen {
    grid-template-columns: 1fr 1fr;
    gap: 3.8rem;
  }
  @include small-screen {
    grid-template-columns: 1fr;
    margin-bottom: 25px;
  }
  a {
    text-decoration: none;
  }
}

.pnTiles {
  display: flex;
  flex-direction: column;
  width: 45%;
  @include small-screen {
    width: 100%;
  }
}

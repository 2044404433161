@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";
@import "../../../style/colors.scss";
@import "../../../style/typography.scss";

.container {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: $spacing-l;
  align-items: center;
  width: 100vw;

  .logo {
    position: absolute;
    width: 20rem;
    margin: $spacing-l 0 0 $spacing-xxl;
  }

  .imageContainer {
    .controlTowerImageTree {
      height: 90vh;
    }
  }
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  background-color: white;
  height: 75vh;
  border-radius: 0 3000px 3000px 0;

  @media (max-width: 1900px) {
    height: 60vh;
  }

  .formContent {
    width: 60%;
    max-width: 60%;
    margin: auto;

    @media (max-width: 1900px) {
      width: 70%;
      max-width: 70%;
    }

    @media (max-width: 1400px) {
      width: 80%;
      max-width: 80%;
    }
  }

  .title {
    font-size: $fontSizeMedium;
    margin-bottom: $spacing-l;
    color: $primary;
    max-width: 60%;
    margin-left: 1rem;

    @media (max-width: 1900px) {
      font-size: $fontSizeMedium;
      max-width: 70%;
    }

    @media (max-width: 1400px) {
      font-size: $fontSizeMedium;
      max-width: 80%;
    }
  }

  .inputField {
    width: inherit;
    margin: $spacing-s;
    .input {
      font-size: $fontSizeMedium;
    }
  }

  .messageBox {
    margin-bottom: $spacing-s;
  }
  .passwordButton {
    margin: $spacing-s 0;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
  }
  @include small-screen {
    align-items: center;
    width: 100%;
  }
}

.passwordButton {
  display: flex;
  justify-content: center;
}

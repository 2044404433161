@import "../../style/sizes.scss";
@import "../../style/mixins.scss";
@import "../../style/colors.scss";

.main {
  height: 100%;
  min-height: calc(85vh - #{$headerHeight});
}

.loggedIn {
  padding-top: calc(#{$headerHeight} + 4rem);
  @include small-screen {
    padding-top: calc(#{$mobileReducedHeaderHeight} + 3.2rem);
    &.loggedInMobile {
      padding-top: calc(#{$mobileFullHeaderHeight} + 3.2rem);
    }
  }
}

.notLoggedIn {
  height: 100vh;
  background-color: $primary;
  display: flex;
  align-items: center;
}

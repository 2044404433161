@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.form {
  display: grid;
  grid-template-columns: 1fr;
  background-color: white;
  height: 75vh;
  border-radius: 0 3000px 3000px 0;

  @media (max-width: 1900px) {
    height: 60vh;
  }

  .container {
    width: 60%;
    max-width: 60%;
    margin: auto;

    @media (max-width: 1900px) {
      width: 70%;
      max-width: 70%;
    }

    @media (max-width: 1400px) {
      width: 80%;
      max-width: 80%;
    }
    .content {
      width: 60%;

      .inputField {
        margin-top: $spacing-m;
        width: 100%;
      }
    }
  }
  .title {
    @extend %sectionTitle;
    color: $primary;
    margin-bottom: $spacing-m;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-m;
  }
}

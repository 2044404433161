@import "../../../style/sizes.scss";
.breadcrumbs {
  position: absolute;
  max-width: 95%;
  width: $maxContentWidth;
  top: 11.5rem;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0.8rem;
}

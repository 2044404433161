@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.login {
  display: grid;
  grid-template-columns: 1fr;
  background-color: white;
  height: 75vh;
  border-radius: 0 3000px 3000px 0;

  @media (max-width: 1900px) {
    height: 60vh;
  }

  .loginContent {
    width: 60%;
    max-width: 60%;
    margin: auto;

    @media (max-width: 1900px) {
      width: 70%;
      max-width: 70%;
    }

    @media (max-width: 1400px) {
      width: 80%;
      max-width: 80%;
    }
  }

  .title {
    font-size: $fontSizeLoginTitle;
    margin-bottom: $spacing-l;
    color: $primary;
    max-width: 60%;

    @media (max-width: 1900px) {
      font-size: $fontSizeLoginTitle;
      max-width: 70%;
    }

    @media (max-width: 1400px) {
      font-size: $fontSizeLoginTitleMedium;
      max-width: 80%;
    }
  }

  .inputField {
    width: inherit;
    margin: $spacing-s;
    .input {
      font-size: $fontSizeMedium;
    }
  }

  .messageBox {
    margin-bottom: $spacing-s;
  }
  .loginButton {
    margin: $spacing-s 0;
  }

  @include small-screen {
    align-items: center;
    width: 100%;
  }
}

.passwordButton {
  margin: 0 0 $spacing-l 0;
  color: $primary;
  display: flex;
  justify-content: center;
  width: 60%;
  color: $primary;

  @media (max-width: 1900px) {
    width: 70%;
    max-width: 70%;
  }

  @media (max-width: 1400px) {
    width: 80%;
    max-width: 80%;
  }
}

.loginButton {
  display: flex;
  justify-content: center;
  width: 60%;

  @media (max-width: 1900px) {
    width: 70%;
    max-width: 70%;
  }

  @media (max-width: 1400px) {
    width: 80%;
    max-width: 80%;
  }
}

@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";
@import "../../../style/typography.scss";
@import "../../../style/mixins.scss";
@import "../../../style/z-index.scss";

.bannerWelcome {
  background-color: #effbff;
  height: 15vh;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  h1 {
    width: $maxContentWidth;
    margin: auto;
  }
}
.bannerImage {
  width: 850px;
}

@import "../../../style/spacing.scss";
@import "../../../style/z-index.scss";

.alert {
  width: 320px;
  max-width: 320px;
  padding: 6px, 16px, 6px, 16px;
  border-radius: 4px;
}

.error {
  border: 2px solid #d32f2f;
}

.warning {
  border: 2px solid #ed6c02;
}

.info {
  border: 2px solid #0288d1;
}

.success {
  border: 2px solid green;
}

@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";

.banner {
  background: url("../../../assets/images/detail-header.jpg") no-repeat right;
  height: $bannerHeight;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.bannerInnerContainer {
  max-width: 95%;
  width: $maxContentWidth;
  margin: auto;
  display: flex;
  h1 {
    margin-top: $spacing-m;
  }
}

.textSpacing {
  margin-bottom: $spacing-m;
}

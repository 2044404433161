@import "../../style/typography.scss";
@import "../../style/spacing.scss";
@import "../../style/mixins.scss";
@import "../../style/sizes.scss";

.trackAndTracePage {
  padding: 4.5rem 0;
}
.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: $spacing-s;
}
.filter {
  margin-top: $spacing-l;
  margin-bottom: $spacing-s;
  display: flex;
  flex-direction: column;

  .flexContainer {
    display: flex;
    align-items: center;
  }

  .dateFilter {
    margin-bottom: $spacing-m;

    .fromDateFilter {
      margin-right: $spacing-m;
    }
    .toDateFilter {
      margin-left: $spacing-m;
    }
  }

  .dateFilter input {
    width: $defaultFieldWith;
  }

  .itemFilter {
    margin-bottom: $spacing-m;
  }

  .itemFilter input {
    width: $defaultFieldWith;
  }
}

.searchFilter {
  .searchInputField {
    width: $defaultFieldWith * 2;
    margin-right: $spacing-m;
    div {
      border-radius: inherit;
    }
  }
}
.itemInputField {
  input {
    padding-right: 50px;
  }
}
.gridPicker {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-s;
}

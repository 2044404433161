$primaryDark: #003350;
$primary: #005d92;
$primaryLight: #e0f8ff;
$primaryLight2: #f0f8fb;
$darkGrey: #6f7173;
$grey: #f3f2f2;
$lightGrey: #e9e6e5;
$darkWhite: #f3f2f2;
$white: #ffffff;
$lightWhite: #f4f4f4;
$blueWhite: #e0f8ff;
$red: #ed0e0e;
$green: #4bd332;
$greenDark: #2d922d;
$blue: #2c7cb2;
$yellow: #ecb20f;
$lightYellow: #f0d998;
$orange: #ffa500;
$black: #292929;

//GENERAL
$affordance: $primary;
$error: $red;
$warning: $yellow;
$info: $primary;
$success: $green;

//TYPOGRAPHY
$fontColor: $black;
$subfontColor: $darkGrey;

//COMPONENTS
$navigationBar: $white;
$navigationSubMenu: $white;
$iconColor: $primary;
$highLight: $primaryDark; //e.g. hover on list items
$borderColor: $grey; //e.g. list items bottom border
$borderColorLight: $darkWhite; //e.g. Accordion item
$contentBorder: $primaryLight;
$pageSubMenu: #fafafa;
$contentItemBgColor: #fafafae5;

/*
EXPORTS TO USE IN JS FILES 
for example theme.js
NOT WORKING WITH CRA v4.0.1 (26 nov 2020)
using temporary solution with exports.module.scss
*/
:export {
  primaryDark: $primaryDark;
  primary: $primary;
  primaryLight: $primaryLight;
  green: $green;
  darkGrey: $darkGrey;
  grey: $grey;
  lightGrey: $lightGrey;
  darkWhite: $darkWhite;
  white: $white;
  blueWhite: $blueWhite;
  lightWhite: $lightWhite;
  iconColor: $iconColor;
}

@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";

.banner {
  background: url("../../../assets/images/detail-header.jpg") no-repeat right;
  height: $bannerHeight;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .bannerInnerContainer {
    max-width: 95%;
    width: $maxContentWidth;
    margin: auto;
    display: flex;
    h1 {
      margin-top: $spacing-m;
    }
    .trackingLink {
      color: $primary;
      text-decoration: none;
      margin-top: $spacing-s;
      font-weight: 500;
      display: flex;
      align-items: center;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.textSpacing {
  margin-bottom: $spacing-m;
}

@import "../../../style/typography.scss";
@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";

.sectionTitle {
  @extend %sectionTitle;
}

.fileList {
  margin-bottom: $spacing-xl;
  @include small-screen {
    margin-bottom: 6.4rem;
  }
}

.quickAccessContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @include medium-screen {
    flex-direction: row;
  }
  @include small-screen {
    flex-direction: column;
  }
}

.notificationsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  @include small-screen {
    width: 100%;
  }
}

.allNewsContainer {
  margin-top: 25px;
}

@import "style/colors.scss";
@import "../node_modules/pn-design-assets/pn-assets/styles/pn-styles.scss";

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: $fontColor;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: $affordance;
}

.active {
  color: $primary;
}

textarea {
  resize: none;
}

ul,
ol {
  list-style: none;
}

@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";

.container {
  display: grid;
  margin: $spacing-s $spacing-l;
  height: fit-content;

  .rule {
    margin: $spacing-s 0;
    display: grid;
    grid-template-columns: 2.4rem 1fr;
    column-gap: $spacing-s;
    align-items: center;
  }
  .notAccepted {
    color: $lightGrey;
  }
  .accepted {
    color: $primary;
  }
}

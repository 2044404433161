@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";

.container {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: $spacing-l;
  align-items: center;
  width: 100vw;

  .logo {
    position: absolute;
    width: 20rem;
    margin: $spacing-l 0 0 $spacing-xxl;
  }

  .imageContainer {
    .controlTowerImageTree {
      height: 90vh;
    }
  }

  .title {
    color: white;
    margin-left: $spacing-m;
  }
}

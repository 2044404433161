@import "../../../../style/colors.scss";

.spinner {
  margin: 0;
  height: fit-content;
}

.spinnerImage {
  width: 30px;
  margin-bottom: 0;
}

@import "../../../../style/spacing.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/colors.scss";

.newsContainer {
  background-color: $white;
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0;
  margin: 0 0 15px 0;
  border: 2px solid $lightGrey;
  height: 200px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  .newsImage {
    background-color: $grey;
    border-radius: 0 20rem 20rem 0;
    display: flex;
    align-items: center;
    align-self: center;
    height: 300px;
    width: 35%;
    overflow: hidden;
    .pnLogo {
      margin: auto;
      width: 130px;
    }
    .uploadedImage {
      margin: auto;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .newsContent {
    display: flex;
    flex-direction: column;
    width: 65%;
    color: $black;
    margin: 0 20px;
    h3 {
      font-size: 18px;
      font-weight: 600;
      margin: 5px 0 15px 0;
    }
    p {
      font-size: 14px;
    }
    .compactBody {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .newsTopInfo {
      display: block;
      justify-content: space-between;
      margin: 10px 0;
      span {
        padding: 5px 10px;
      }
      .date {
        font-size: 12px;
        float: right;
      }
      .infoBubble {
        font-size: 12px;
        margin: 0;
        background-color: #e9e6e5;
        border-radius: 15px;
        float: left;
        margin-right: 5px;
      }
    }
  }
  .readMore {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    font-weight: 700;
    &:hover {
      a {
        margin-right: 2.5px;
        margin-left: 7.5px;
        transition: all 0.2s;
      }
      svg {
        margin-left: 2.5px;
        margin-right: 7.5px;
        transition: all 0.2s;
      }
    }
    svg {
      margin-left: 7.5px;
    }
    a {
      margin-right: 7.5px;
      text-decoration: none;
    }
  }
}
